<template>
  <div class='loanPage'>
    <div class='loan-header-text' v-if="productInfo!=null">
      该借款服务由{{productInfo.appName}}提供，本平台不承担任何责任
    </div>
    <!-- 产品名及客服电话 -->
    <div class='loan-product' v-if="productInfo!=null">
      <div class='loan-product-left'>
          <img class='loan-pro-img' :src='productInfo.appLogo' alt='产品logo' />
          <span class='loan-pro-name'>{{productInfo.appName}}</span>
      </div>
      <div class='loan-product-tel'>客服电话：{{productInfo.customerNumbers}}</div>
    </div>
    <!--借款内容-->
    <div  class="loan-content" v-if="dataInfo!=null">
      <!-- 借款 -->
      <div class='loanMain'>
        <div class='loanCard'>
          <div class='loanMoeny'>
            <div class='moenyIcon'>¥</div>
            <input class='moenyNum' type='number' pattern="[0-9]*" v-model="form.loanAmount"    @change="changeInput($event)" :placeholder='dataInfo.creditAmtMax'/>
          
          </div>
          <div class='loanDes' @click='setLoanAll'>全部借出</div>
        </div>
        <div class='loandetail'>单笔{{dataInfo.creditAmtMin}}起借，最高可借{{dataInfo.creditAmtMax}}元</div>
      </div>
      <!-- 期限，用途，账户， -->
      <div class='loanContain'>
        <div class="loanLine" @click="timeShow">
          <div class='timeR time-qx'>借款期限</div>
           <div class='timeL'>
              <input class='loanInput' placeholder='请选择' readonly='readonly' v-model='timeVal' />
              <span class='loanIcon'></span>
            </div>
        </div>
        <div class='loanFecord'>
          <div class="loanLine loanBorder" @click="purShow" >
            <div class='timeR'>借款用途</div>
            <div class='timeL'>
              <input class='loanInput' placeholder='请选择' readonly='readonly' v-model='purposeVal' />
              <span class='loanIcon'></span>
            </div>
          </div>
          <div class="loanLine loanBorder" @click='goback'>
            <div class='timeR'>收款账户</div>
            <div class='timeL'>
              <!--   item.openBankName +(item.bankAccountShow)-->
              <input class='loanInput'  readonly='readonly' v-model="bankNew" placeholder="添加银行卡"  @change="changebank($event)"/>
              <span class='loanIcon'></span>
            </div>
           
          </div>
        </div>
        <div class='loanThree' v-if="loanRetel!=null">
          <div class="loanLine loanBorder">
            <div class='timeR'>到账金额</div>
            <div class='timeL'>￥{{loanRetel.arrayAmt}}</div>
          </div>
          <div class="loanLine loanBorder">
            <div class='timeR line-frist-text'>首次应还</div>
            <div class='timeL line-frist-number'>￥{{loanRetel.fistRepayAmt}}({{loanRetel.fistRepayDate}})</div>
          </div>
          <div class="loanLine loanBorder">
            <div class='timeR'>还款计划</div>
            <div class='timeL seeDetails' @click='checkDes'>查看详情</div>
          </div>
        </div>
      </div>
      <div class="footer-text">
        <div class="footer-p">以下相关协议自愿与机构签署，</div>
        <div class="footer-p">与本平台无关，请您仔细阅读。</div>
      </div>
    </div>

    <!-- 底部按钮 -->
    <div class='loanFooter'>
      
      <div class="footer-b">
         <div class='footerDes'>
            <span class='footerF'>点击下一步表示您已经同意</span>
            <span class='footerT' @click='goProtocol'>相关协议</span>
          </div>
          <div class="btn" @click="btnNext">下一步</div>
      </div>
     
    </div>


    <!-- 借款期限 -->
    <Modal :show="showTime" @cancel="cancel" :showCancel="true" class='bank-purpose' :title="'借款期限'" >
            <div class='purpose-m'>
              <div class='purpose-main'>
                  <div class='purpose-line' v-for="item in timeList" :key="item"  @click='checkTime(item)' >
                    <div class='purpose-text' >
                      <div>{{item}}</div>
                    </div>
                  </div>
              </div>
           </div>

    </Modal>
    <!-- 借款用途 --> 
    <Modal :show="showpurpose" @cancel="cancel" :showCancel="true" class='bank-purpose' :title="'借款用途'" >
        <div class='purpose-m'>
             <div class='purpose-main'>
                  <div class='purpose-line' v-for="item in purposeList" :key="item"  @click='checkPurpose(item)' >
                    <div class='purpose-text' >
                      <div>{{item}}</div>
                    </div>
                  </div>
            </div>
        </div>
       
    </Modal>
    <!-- 还款计划 -->
    <BankList :show="showDialog" @cancel="cancel" :showCancel="true" :title="'还款计划'" >
      <div class='backpage' v-for="item in repayPlans" :key='item.planIndex'>
        <div class='backPeriod'>第{{item.planIndex}}期</div>
        <div class='backright'>
          <div class='backNum'>{{item.planAmt}}</div>
          <div class='backDate'>还款日：{{item.planDate}}</div>
        </div>
      </div>
    </BankList>
    <!-- 添加银行卡 -->
    <Modal :show="showback" @cancel="cancel" :showCancel="true" class='bankpages' :title="'选择收款账户'">
      <div class='modalMain'>
        <div class='modalContain'>
          <div class='modalLine' v-for="item in bankLists" :key="item.bankAccount"  @click='checkBank(item)' >
            <img :src='item.logoImg' alt='' class='lineIcon'/>
            <div class='lineText' >
              <div>{{item.openBankName}}（{{item.bankAccountShow}}）</div>
              <!-- 银行卡的选中的状态 -->
              <div :class="[chenckStatus==item.openBankName+item.bankAccountShow ?'checkedIcon':'']"></div>
            </div>
          </div>
      
          <div class='modalLine' @click="addBank">
            <div class='lineBank'></div>
            <div class='lineText'>添加新的银行卡</div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </Modal>
    <!-- 借款确认 -->
    <Modal :show="showUse" @cancel="cancel" :showCancel="true" class='loan-dialog' :title="'借款确认'" >
      <!-- <div class='bankmain'> -->
      <div class='modal-Mains'>
        <div class='modal-Contains'>
          <div class='modal-Lines'>
            <div class='line-Texts'>借款金额</div>
            <div class='line-bank-text'>¥{{loanRetel.arrayAmt}}</div>
          </div>
          <div class='modal-Lines'>
            <div class='line-Texts'>借款期限</div>
            <div class='line-bank-text'>{{loanRetel.times}}</div>
          </div>
          <div class='modal-Lines'>
            <div class='line-Texts'>首期应还</div>
            <div class='line-bank-text'>¥{{loanRetel.fistRepayAmt}}</div>
          </div>
          <div class='modal-Lines'>
            <div class='line-Texts'>收款账户</div>
            <div class='line-bank-text'>{{bankIn.openBankName}}({{bankIn.bankAccountShow}})</div>
          </div>
          <div class='loanconfirm-btn' @click="loanSubmit">确认</div>
        </div>
      </div>
      <!-- </div> -->
    </Modal>
    <!-- 二次绑卡弹框 -->
    <Modal :show="showCard" @cancel="bankCancel" :showCancel="true" class='bank-card' :title="'银行卡验证'" >
        <div class='bank-card-main'>
          <div class='bank-card-text'>应资方要求，需要对银行卡做二次验证</div>
          <div class='pay-Mobile payout-line'>
               <div class='mobile-Text'>验证码</div>
               <input class='moblie-Num'  type='number' oninput="if(value.length>6)value=value.slice(0,6)" v-model='verifyCode' @change="changeCode($event)" placeholder="填写验证码"/>
               <div class="mobile-Code" @click='GetVerifyCode' v-show="canSendCode">{{ verifyTxt}}</div>
               <div class="mobileCode-min" @click='GetVerifyCode' v-show="!canSendCode">{{count}}秒</div>
          </div>
          <div class="bank-card-btn" @click='bindCard'>确定</div>
        </div>
    </Modal>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import {routerTag} from '../../utils/index'
import Moment from 'moment'; 
import {Toast} from 'vant';
import APP from '../../utils/APP'
import API from '../../server/api'
// 首页额度
import BankList from '@/components/BankList/index.vue'
import Modal from '@/components/Modal/Modal.vue'

export default {
  name: 'Index',
  components: {
    BankList,
    Modal
  },
  setup () {

    // 设置标题Title
    APP.SET_TITLE('借款')

    const state = reactive({
      showTime:false,//期限
      showpurpose:false,//借款用途
      showDialog: false, //还款详情
      showback: false,//银行卡
      showUse: false,//借款确认
      showCard:false,//银行卡二次绑卡
      chenckStatus:null,
      // 提交表单
      form:{
        loanAmount:'', // 借款金额 
        bankAccount:'', // 还款卡号
        clientNo:'',// 客户编号
        productName:'',// 产品名称
        prodcode:'',//产品标识
      },
   
      purposeVal:'',//用途value
      timeVal:'',//期限value
      repayPlanList: [],//还款计划列表
      agreementsList:null,//借款协议
      bankLists:[],//银行卡
      bankIn:null,//选中的银行卡
      bankNew:'',
      loanInfo:null,//借款试算
      FristLoan:null, //首次应还
      repayPlans:[],
      feestates:false,
      Moment,
      verifyCode: '',
      countdown: 60,
      verifyTxt: "点击获取验证码",
      canSendCode: true,
      productInfo:null,//产品信息
      dataInfo: null,// 初始化的授信数据
      purposeList:[],// 借款用途
      timeList:[], // 借款期限
      loanRetel:null,//失算
      telNum:'',//银行卡手机号
      bankName:'',
      bankCode:'',
      count:60
    })
    const timeShow=()=>{
      state.showTime=true
    }
    //借款用途
   const purShow=()=>{
     state.showpurpose=true
   }
   const checkTime=(item)=>{
     state.timeVal=item
     state.showTime=false
   }
   const checkPurpose=(item)=>{
     state.purposeVal=item
     state.showpurpose=false
   }
   const bankCancel=()=>{
     state.bankNew=''
     state.showCard=false
   }
   const changebank=()=>{

   }
    //点击选中银行卡
    const checkBank = (item) => {
      state.chenckStatus = item
      state.bankIn=item
      state.form.bankAccount=item.bankAccount
      state.bankNew=item.openBankName +'('+ item.bankAccountShow +')'
      state.telNum=item.ownerMobile
      state.bankName=item.openBankName
      state.bankCode=item.openBankCode
      GetVerifyCode()
    }
    //借款确认
    const goUse = () => {
      state.showUse = true
    }
    //银行卡弹框
    const goback = () => {
      state.showback = true
      bankinit()
    }
    // 还款计划的弹框显示
    const checkDes = () => {
      state.showDialog = true

    }
    const cancel = (show) => {
      state.showDialog = show
      state.showback = show
      state.showUse = show
      state.showCard=show
      state.showpurpose=show
      state.showTime=show
      state.chenckStatus = null
     
    }
    const confirm = (show) => {
      state.showDialog = show
      state.showback = show
      state.showUse = show
      state.showCard=show
      state.showpurpose=show
      state.timeShow=show
    }

    // 初始化授信额度接口数据
    const initLoan = async () => {
      const result = await API.loanInits({   })
      state.productInfo=result.productInfo;
      state.dataInfo=result.loanInfo;
      state.purposeList=result.loanInfo.useWay;
      state.timeList=result.loanInfo.interval;
      state.agreementsList=result.protocol;
      window.localStorage.setItem('protocol',JSON.stringify(state.agreementsList))
     
    }
    //全部借出
    const setLoanAll = () => {
      
      state.form.loanAmount=state.dataInfo.creditAmtMax
     
    }
     //获取借款的input输入值
    const changeInput=(e)=>{
      console.log(e.target.value,33);
      if(e.target.value<3000){
         Toast('请输入正确的借款额度')
          return false
      }
       if (e.target.value % 1000 !== 0) {
            Toast('请输入1000的整数倍');
            return false;
        }
      state.form.loanAmount=e.target.value;
       
      if(state.form.loanAmount!='' && state.timeVal!='' && state.purposeVal!='' && state.bankNew !=''){
        initLoanFee()
      }
    }
    // 借款测算
    const initLoanFee = async () => {

      const idNO=window.localStorage.getItem('appNo')
      const orderNo=window.localStorage.getItem('orderNo')
      const {bankAccount,loanAmount} = state.form;
      const  result= await API.loanFee({
        orderNo:orderNo,
        amount:loanAmount,//金额
        unit:2,//期限
        interval:state.timeVal,//用途
        appNo:idNO,
        bankCard:bankAccount

      })
      state.loanRetel=result.LoanDetail
      state.repayPlans=result.LoanPlan.repayPlan

      if(state.repayPlans!=null){
        state.feestates=true
      }
       
    }
    //添加银行卡
    const addBank=()=>{
    
      state.showback = false
      const protoUrl=routerTag+"bankcard"
      APP.JUMP_H5(protoUrl,'添加银行卡')
       

    }
    //获取银行卡列表
    const bankinit= async () => {
      const result= await API.bankLists({})
      state.bankLists=result.bankCardList;
    }
    //下一步 借款测算
    const btnNext=()=>{
     
      if(state.form.loanAmount==''){
        Toast('请输入借款额度')
        return false
      }
      if(state.timeVal==''){
         Toast('请选择借款期限')
         return false
      }
      if(state.purposeVal==''){
         Toast('请选择借款用途')
         return false
      }
      if(state.bankNew ==''){
        Toast('请选择银行卡')
         return false
      }
      if(state.feestates==false){
        
         initLoanFee()
      }else{
        //调起借款弹框
        state.showUse=true
        // loanSubmit()
      }
     
    }
    //借款提交
    const loanSubmit=async()=>{
       window.TDAPP.onEvent("借款页-点击提交-进入");
      const {bankAccount,loanAmount} = state.form;
      const idNO=window.localStorage.getItem('appNo')
      const orderNo=window.localStorage.getItem('orderNo')
      if (loanAmount % 1000 !== 0) {
            Toast('请输入1000的整数倍');
            return false;
      }
      if (loanAmount < 3000) {
            Toast('最低借款金额不能少于3000');
            return false;
      }
      //借款成功跳转到借款成功页
      const result= await API.loanSubmit({
        appNo:idNO,
        bankCard: bankAccount,
        bankCode: state.bankCode,
        bankName:state.bankName,
        bankPhone: state.telNum,
        loanAmount:loanAmount,
        loanUse: state.purposeVal,//用途
        optionNo: '001',//额度选项编号
        orderNo:orderNo,
        termPeriod: 12,//借款期限
        termUnit: 2
      })
      const loanReUrl=routerTag+"loanResult"
      APP.JUMP_H5(loanReUrl,'申请完成') 
      
    }
    //协议跳转
    const goProtocol=()=>{
        const loanProUrl=routerTag+"LoanProtocol"
        APP.JUMP_H5(loanProUrl,'协议')
    
    }
     //获取验证码的input输入值
    const changeCode=(e)=>{
            console.log(e.target.value,33);
            if(e.target.value=='' || e.target.value.length > 6){
                Toast('请输入正确的验证码')
                return false
            }
            state.verifyCode=e.target.value;
        
        }
    //获取验证码
    const GetVerifyCode= async ()=>{
            window.TDAPP.onEvent("借款页-获取验证码-进入");
            const {bankAccount,clientNo,clientCell}=state
            const prodNo=window.localStorage.getItem('appNo');
            const orderNo=window.localStorage.getItem('orderNo')
            if(bankAccount==''){
                Toast('请选择银行卡')
                return false;
            }
            const result= await API.bankSms({
                appNo:prodNo,//产品Id
                orderNo:orderNo,//订单编号
                ownerName: state.bankIn.ownerName,//姓名
                ownerIdNo: state.bankIn.ownerIdNo,//身份证
                bankAccount: state.bankIn.bankAccount,//卡号
                mobile: state.bankIn.ownerMobile, //预留手机号
                cardType:1 ,//默认是1
                scene :1 //默认是1
            })
            if(result.needVerify==0){
              
                state.showback = false
            }else{
              //需要二次绑卡
              state.showCard=true
              state.showback = false
              countdowns()
            }
    } 
    // 验证码倒计时
    const countdowns=() =>{
           const TIME_COUNT = 60;
            if (!state.timer) {
                state.count = TIME_COUNT;
                state.canSendCode = false;
                state.timer = setInterval(() => {
                    if (state.count > 0 && state.count <= TIME_COUNT) {
                        state.count--;
                    } else {
                        state.canSendCode = true;
                        clearInterval(state.timer);
                        state.timer = null;
                    }
                }, 1000)
            }
    }
    const bindCard= async ()=>{
      window.TDAPP.onEvent("借款页-点击绑卡-进入");
      const prodNo=window.localStorage.getItem('appNo');
      const orderNo=window.localStorage.getItem('orderNo')
      const result = await API.bankBind({
        appNo:prodNo,//产品Id
        orderNo:orderNo,//订单编号
        ownerName:state.bankIn.ownerName,//姓名
        ownerIdNo:state.bankIn.ownerIdNo,//身份证
        bankAccount:state.bankIn.bankAccount,//卡号
        mobile: state.bankIn.ownerMobile, //预留手机号
        cardType:1, //默认是1
        scene :1, //默认是1
        validCode: state.verifyCode //验证码
      })
      if(result.needVerify==0){
         state.showCard=false;
         Toast('绑卡成功')
         return false
      }else if(result.needVerify== -1){
        Toast(result.remark)
        return false
      }else{

      }
    }
    // 页面挂载完成
    onMounted(() => {
      //调取刷新方法
      // APP.SET_REFESH()
      state.form.prodcode=window.localStorage.getItem('appNo')
      initLoan();
      bankinit()  
      // APP.show_View('只差一步，钱就到账了，您真的要放弃么')
    })
    
    return {
      ...toRefs(state),
      purShow,
      checkPurpose,
      checkBank,
      goUse,
      goback,
      checkDes,
      cancel,
      confirm,
      setLoanAll,
      initLoan,
      bankinit,
      goProtocol,
      changeInput,
      addBank,
      btnNext,
      loanSubmit,
      changeCode,
      GetVerifyCode,
      timeShow,
      checkTime,
      bindCard,
      bankCancel
    }
  }
  
}
</script>


<style scoped lang='less' src='./index.less'></style>
